import React from 'react';
import { useTranslation } from 'react-i18next';

import { supportRequestURL } from '../../utils/constants';
import DialogForm from './DialogueForm';

export default function CustomerSupport(): JSX.Element {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation('support');
  return (
    <>
      <a
        className="h-10 inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        href={supportRequestURL}
        target="_blank"
        rel="noreferrer">
        {t('contact')}
      </a>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-end p-6 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}>
                    <span className="bg-transparent text-black opacity-25 h-1 w-1 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative px-11 pb-12 my-1">
                  <div className="mx-2 text-blueGray-500 text-lg leading-relaxed">
                    <DialogForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
