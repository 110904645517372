import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../components/Button';

export const EndPage = (): JSX.Element => {
  const { t } = useTranslation(['end']);
  const content = t('end:endMessage');

  return (
    <div className="px-4 py-6 sm:px-0">
      <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-6 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 xl:mb-28">
        <div className="text-center">
          <h4 className="text-xl font-bold font-sans tracking-tight leading-10 text-gray-800 sm:leading-none">
            {t('end:title')}
          </h4>
          <p className="text-md mt-3 font-mw text-sub-hl-18 text-gray-600 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5">
            <Markdown>{content}</Markdown>
          </p>
          <div
            className={
              'mt-5 mb-10 sm:mt-8 flex-col sm:flex-row-reverse sm:gap-10 flex justify-center'
            }>
            <Button
              type={'link'}
              className="mb-5 sm:mb-0"
              href={t('end:endButtonMSSettingsLink')}
              target="_blank">
              {t('end:endButtonMSSettingsLabel')}
            </Button>
            <Button
              type={'link'}
              className="mb-5 sm:mb-0"
              href={t('end:endButtonZendeskArticleLink')}
              target="_blank">
              {t('end:endButtonZendeskArticleLabel')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
