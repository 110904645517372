import { useMutation } from '@apollo/client';
import { Button, Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Alert } from '../components/alerts';
import { InputField, RadioSelect, Select } from '../components/forms';
import FormField from '../components/forms/FormField';
import { FormInfo } from '../components/forms/FormInfo';
import CustomerSupport from '../components/supportForm/SupportModal';
import { useAuth } from '../context/auth';
import {
  ConnectedSystems,
  CreateReferralDocument,
  CreateReferralMutation,
  CreateReferralMutationVariables,
  CreateReferrerDocument,
  CreateReferrerMutation,
  CreateReferrerMutationVariables,
  GetReferrersDocument,
  Service,
  UpdateReferralDocument,
  UpdateReferralMutation,
  UpdateReferralMutationVariables,
  useGetReferralQuery,
  useGetReferrersQuery,
} from '../graphql/generated/graphql';
import { API_NAMES, OTHER_INTERFACE, SUCCESS_PAGE_PATH } from '../utils/constants';
import { OnboardingFormState } from '../utils/constants/types';
import { validateInput } from '../utils/helpers';
import { sortAlpha } from '../utils/helpers/sort';

const schema = yup.object().shape({
  hasSystemsAlreadyConnected: yup.boolean().required(),
  otherReferral: yup.string().test('isValidReferrer', (val) => validateInput(val)),
  connectedPlatforms: yup.array(yup.string()).nullable(),
  otherPlatforms: yup
    .string()
    .required()
    .when('connectedPlatforms', (connectedPlatforms: string[], schema) => {
      return connectedPlatforms?.includes(OTHER_INTERFACE)
        ? schema.required()
        : schema.notRequired().nullable(true);
    }),
  // make field mandatory only when user select an ERP platform
  hasAmazonTransactionsIncluded: yup
    .boolean()
    .when('connectedPlatforms', (connectedPlatforms: undefined | string[], schema) => {
      return connectedPlatforms?.length
        ? schema.required()
        : schema.notRequired().nullable(true);
    }),
});

type Props = {
  state: ConnectedSystems;
  updateState: (type: keyof OnboardingFormState, data: ConnectedSystems) => Promise<void>;
};

// Vat Specifics data collection form
export const ConnectedSystemsForm = ({ state, updateState }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { clientId } = useAuth();
  const { t } = useTranslation(['systems', 'others']);
  const options = t('others:yesNoOptions', {
    returnObjects: true,
  }).map((option) => ({
    label: option.label,
    value: String(option.value),
  }));

  // Getting the data for the referrers
  const { data: getRefferrers, loading: referrersLoading } = useGetReferrersQuery({
    context: { clientName: API_NAMES.REFERRAL_API },
  });

  // Getting the data for the referrals
  const { data: getReferral, loading: referralLoading } = useGetReferralQuery({
    context: { clientName: API_NAMES.REFERRAL_API },
    variables: {
      clientId,
    },
  });

  // Selecting the referrer from client referral
  const selectedReferral = referralLoading
    ? ''
    : getReferral && getReferral.getReferral && getReferral.getReferral.referrer;

  let isDisable = true;
  if (!selectedReferral) {
    isDisable = false;
  }

  const isManagedReferrer =
    selectedReferral && selectedReferral.managed === false ? true : false;

  const referrers = getRefferrers?.getReferrers?.items?.filter(Boolean) || [];
  let referrersOptions = referrers
    .map((item) => {
      if (item && item?.managed === true) {
        return {
          label: item.label,
          value: item.id,
        };
      }
    })
    .filter(Boolean);

  referrersOptions = [
    ...referrersOptions,
    ...[
      {
        label: 'others',
        value: 'others',
      },
    ],
  ]
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((ref) => ref!)
    .sort((prev, next) => {
      return sortAlpha(prev, next);
    });

  // Creating a referrer when other selected
  const [addReferrer, { loading: referrerMutationLoading }] = useMutation<
    CreateReferrerMutation,
    CreateReferrerMutationVariables
  >(CreateReferrerDocument);

  // creating referral based on ClientID
  const [createReferral] = useMutation<
    CreateReferralMutation,
    CreateReferralMutationVariables
  >(CreateReferralDocument);

  // updating a referral based on clientID
  const [updateReferral] = useMutation<
    UpdateReferralMutation,
    UpdateReferralMutationVariables
  >(UpdateReferralDocument);

  // Initialize RHF with current progress of the personal data form
  const methods = useForm<
    ConnectedSystems & {
      otherReferral: string;
      referral: string;
    }
  >({
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
    },
  });

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    clearErrors,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset(state);
  }, [state, reset]);

  const hasSystemsAlreadyConnected = watch('hasSystemsAlreadyConnected');
  const hasAmazonVCS = watch('hasAmazonVCSEnabled');
  const otherReferral = watch('otherReferral');
  const referral = watch('referral');

  const hasOthersReferral = referral === 'others' ? true : false;
  const hasNonSupportedConnector = watch('connectedPlatforms')?.includes(OTHER_INTERFACE);
  const addOtherReferrer = async (): Promise<string> => {
    const createOtherReferrer = await addReferrer({
      context: { clientName: API_NAMES.REFERRAL_API },
      variables: {
        clientId,
        name: otherReferral.toString().toLowerCase(),
        managed: false,
      },
      refetchQueries: [
        {
          query: GetReferrersDocument,
        },
      ],
    });

    return createOtherReferrer.data?.createReferrer?.id || '';
  };

  // creating other referer and referral and attaching the referrerId to referral
  const createOtherReferral = async () => {
    const otherReferrerId = await addOtherReferrer();
    createReferral({
      context: { clientName: API_NAMES.REFERRAL_API },
      variables: {
        clientId,
        referrerId: otherReferrerId,
        service: Service.AutoOnboarding,
      },
    });
  };

  const verifyReferrals = () => {
    referrerMutationLoading
      ? 'Creating referrer..'
      : hasOthersReferral
      ? createOtherReferral()
      : createReferral({
          context: { clientName: API_NAMES.REFERRAL_API },
          variables: {
            clientId,
            referrerId: referral,
            service: Service.AutoOnboarding,
          },
        });
  };

  // handling back button
  const onBackButton = () => {
    clearErrors();
    navigate('/onboarding/vat_specifics');
  };

  type OnSubmitHandler = ConnectedSystems & { otherReferral: string; referral: string };

  // Click handler for the next button
  const onSubmit = () => {
    return async (data: OnSubmitHandler) => {
      setIsLoading(true);
      if (!hasNonSupportedConnector) {
        data.otherPlatforms = '';
      }

      const { referral, otherReferral, ...formData } = data;

      if (isDisable === false) {
        verifyReferrals();
      }

      try {
        await updateState('connectedSystemsForm', formData);
        navigate(SUCCESS_PAGE_PATH);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
  };

  const supportedPlatforms = t('systems:connectedPlatforms.options', {
    returnObjects: true,
  }).sort((prev, next) => {
    return sortAlpha(prev, next);
  });

  supportedPlatforms.push({
    label: t('systems:connectedPlatforms.Others'),
    value: OTHER_INTERFACE,
  });

  return (
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <FormInfo
        header={t('systems:title')}
        description={t('systems:sidebarDescription')}
      />
      <div className="mt-5 md:mt-0 md:col-span-2">
        <FormProvider {...methods}>
          <form>
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6 md:p-0">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <FormField
                    id={'connectedPlatforms'}
                    label={t('systems:connectedPlatforms.label')}>
                    <Controller
                      name="connectedPlatforms"
                      control={control}
                      render={({ field }) => {
                        // TODO fix this!
                        // @ts-ignore
                        const values: string[] = field.value || [];
                        return (
                          <CheckboxGroup
                            value={values}
                            onChange={(values) => {
                              field.onChange(values);
                            }}>
                            <Stack spacing={[1, 5]} direction={['column', 'column']}>
                              {supportedPlatforms.map(({ value, label }) => (
                                <Checkbox key={value} value={value} isChecked={false}>
                                  {label}
                                </Checkbox>
                              ))}
                            </Stack>
                          </CheckboxGroup>
                        );
                      }}
                    />
                  </FormField>
                  {hasNonSupportedConnector && (
                    <div className="col-span-6 mt-4">
                      <InputField
                        id="hasNonSupportedConnector"
                        label={t('systems:hasNoConnectedPlatform.label')}
                        error={errors.otherPlatforms}
                        error_msg={t(
                          'systems:hasNoConnectedPlatform.error.OtherConnectedPlatform',
                        )}
                        {...register('otherPlatforms')}
                      />
                    </div>
                  )}
                  {hasNonSupportedConnector && (
                    <div className="mt-4">
                      <Alert
                        alertType="warning"
                        header={t('systems:connectedPlatforms.alertHeader')}
                        message={t('systems:connectedPlatforms.alert')}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <RadioSelect
                    id="hasAmazonTransactionsIncluded"
                    name="hasAmazonTransactionsIncluded"
                    error={errors.hasAmazonTransactionsIncluded}
                    label={t('systems:hasAmazonTransactionsIncluded.label')}
                    options={options}
                  />
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <RadioSelect
                    id="hasSystemsAlreadyConnected"
                    name="hasSystemsAlreadyConnected"
                    label={t('systems:hasSystemsAlreadyConnected.label')}
                    error={errors.hasSystemsAlreadyConnected}
                    options={options}
                  />
                  {!hasSystemsAlreadyConnected && (
                    <div className="mt-4">
                      <Alert
                        alertType={'warning'}
                        header={t('systems:hasSystemsAlreadyConnected.alertHeader')}
                        message={t('systems:hasSystemsAlreadyConnected.alert')}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <RadioSelect
                    id="isCSVImportEnabled"
                    name="isCSVImportEnabled"
                    error={errors.isCSVImportEnabled}
                    label={t('systems:isCSVImportEnabled.label')}
                    tooltip={t('systems:isCSVImportEnabled.toolTip')}
                    options={options}
                  />
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <RadioSelect
                    id="isTaxdooApiEnabled"
                    name="isTaxdooApiEnabled"
                    error={errors.isTaxdooApiEnabled}
                    label={t('systems:isTaxdooApiEnabled.label')}
                    tooltip={t('systems:isTaxdooApiEnabled.toolTip')}
                    options={options}
                  />
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <RadioSelect
                    id="hasAmazonVCSEnabled"
                    name="hasAmazonVCSEnabled"
                    error={errors.hasAmazonVCSEnabled}
                    label={t('systems:hasAmazonVCSEnabled.label')}
                    options={options}
                  />

                  {hasAmazonVCS && (
                    <div className="mt-4">
                      <Alert
                        alertType="warning"
                        header={t('systems:hasAmazonVCSEnabled.alertHeader')}
                        message={t('systems:hasAmazonVCSEnabled.alert')}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  {referrersLoading || referrersOptions.length === 0 ? (
                    <span>&apos;Retrieving referrers...&apos;</span>
                  ) : isManagedReferrer ? (
                    referralLoading ? null : (
                      selectedReferral && (
                        <div>
                          <Select
                            id="referral"
                            label={t('systems:referral.label')}
                            defaultValue={selectedReferral ? selectedReferral.id : ''}
                            isDisabled={isDisable}
                            options={[
                              {
                                label: selectedReferral.label,
                                value: selectedReferral.label,
                              },
                            ]}></Select>
                        </div>
                      )
                    )
                  ) : referralLoading ? null : selectedReferral && selectedReferral ? (
                    <Select
                      defaultValue={selectedReferral ? selectedReferral.id : ''}
                      isDisabled={isDisable}
                      id="referral"
                      label={t('systems:referral.label')}
                      // TODO fix this!
                      // @ts-ignore
                      options={referrersOptions}
                      {...register('referral')}
                    />
                  ) : (
                    <Select
                      isDisabled={isDisable}
                      id="referral"
                      label={t('systems:referral.label')}
                      // TODO fix this!
                      // @ts-ignore
                      options={referrersOptions}
                      {...register('referral')}
                    />
                  )}

                  {hasOthersReferral && (
                    <div className="col-span-6 mt-4">
                      <InputField
                        id="otherReferral"
                        label={t('systems:otherReferral.label')}
                        error={errors.otherReferral}
                        error_msg={t('systems:connectedPlatforms.errors.otherReferral')}
                        {...register('otherReferral')}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-8 flex flex-wrap items-center justify-between">
              <CustomerSupport />
              <div className="mt-8 py-3 text-right sm:px-3 md:px-0 flex justify-end">
                <Button variant="outline" onClick={onBackButton}>
                  {t('others:button.back.label')}
                </Button>
                <Button
                  color={'white'}
                  ml={2}
                  onClick={handleSubmit(onSubmit())}
                  isLoading={isLoading}>
                  {t('others:button.submit.label')}
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
